.container {
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #000;
	flex-direction: column;
}

.title {
	font-size: 3em;
	color: rgb(210, 206, 206);
}
