.cardContainer {
	width: 30%;
	background-color: #fff;
	border-radius: 10px;
	padding: 20px;
}

@media screen and (max-width: 900px) {
	.cardContainer {
		width: 90%;
		background-color: #fff;
		border-radius: 10px;
		padding: 20px;
	}
}

.container {
	width: '100%';
	max-width: 635;
	padding-top: 24px;
}

.inputContainer {
	margin-top: 24px;

	.input {
		width: 50%;
		height: 73px;
		background-color: transparent;
		border: 1px solid #d9d9d9;
		border-radius: 5px;
		padding-left: 24px;
		padding-top: 20px;
		padding-bottom: 20px;
		font-size: 24px;
		color: #fff;
	}

	.input::placeholder {
		font-family: 'Inter';
		font-size: 24px;
		color: #9e9b9b;
	}
}
