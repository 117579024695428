.packContainer {
   padding-top: 185px;
   padding-bottom: 124px;
   padding-left: 400px;
   padding-right: 400px;
}

.packs {
    margin-top: 54px;
    display: flex;
	flex-direction: row;
	justify-content: center;
	overflow-y: auto;

    .packImageContainer {
        width: 120px;
        height: 120px;
        border-radius: 5px;
        padding-top: 30px;
        padding-bottom: 32px;
        margin-bottom: 20px 
    
    }

    .packName {
        font-size: 24px;
        font-weight: 700
    }

    .packInfo {
        margin-top: 10px;

        .packQtyTracks {
            font-size: 20px;
            font-weight: 400;
            margin-bottom: 0;
        }

        .packPrice {
            font-size: 20px;
            font-weight: 600;
        }
    }
}

