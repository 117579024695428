.titleContainer {
	margin-top: 16px;
	margin-bottom: 16px;
}

.headerSubtitle {
	margin-top: 14px;
	font-size: 16px;
	font-weight: 700;
}

.bodySubtitle {
	font-size: 24px;
	font-weight: 700;
}

.divider {
	margin-top: 18px;
	width: 100%;
	border: 1px solid #ffffff;
}

.playlist {
	margin-top: 12px;

	.playlistHeader {
		margin-top: 18px;
		margin-bottom: 20px;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
	}

	.playlistTrack {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding-left: 13px;
		padding-right: 22px;
	}

	.trackDetail {
		display: flex;
		flex-direction: row;
		align-items: center;
	}

	.trackImage {
		width: 50px;
		height: 50px;
	}

	.trackName {
		font-size: 18px;
		font-weight: 400;
	}

	.trackArtist {
		font-size: 15px;
		font-weight: 300;
	}
}

.lastTracks {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	overflow-y: auto;

	.track {
		width: 85px;
		height: 85px;
		margin-left: 30px;
	}

	.lastTrackName {
		font-size: 14px;
		font-weight: 700;
		margin-left: 30px;
		margin-bottom: 0;
	}

	.lastTrackArtist {
		font-size: 12px;
		font-weight: 400;
		margin-left: 30px;
	}
}

// .pageHome {
// 	display: flex;
// 	justify-content: center;
// 	padding: 20px;
// 	height: 100vh;
// }

// .logoContainer {
// 	height: 100px;
// 	margin: 0 auto;
// }

// .authFormContainer {
// 	display: flex;
// 	justify-content: center;
// 	align-items: center;
// 	width: 100%;
// 	height: 100vh;
// 	position: relative;
// 	bottom: 120px;
// }

// .authForm {
// 	width: 420px;
// 	padding: 40px 15px;
// 	border-radius: 8px;
// 	background: #fff;
// 	backdrop-filter: blur(10px);
// 	-webkit-backdrop-filter: blur(10px);
// 	border-radius: 10px;
// 	border: 1px solid rgba(255, 255, 255, 0.18);
// }

// .authFormContent {
// 	padding-left: 5%;
// 	padding-right: 5%;
// }

// .authFormTitle {
// 	text-align: center;
// 	margin-bottom: 1em;
// 	font-size: 22px;
// 	color: rgb(34, 34, 34);
// 	font-weight: 800;
// }

// label {
// 	font-size: 14px;
// 	font-weight: 600;
// 	color: #000;
// 	margin-bottom: 10px;
// 	text-align: left;
// 	width: 100%;
// }

// .input {
// 	padding: 8px 15px;
// 	width: 100%;
// 	border-radius: 8px;
// 	border: 1px solid #c3c3c3;
// }

// .phoneInput {
// 	width: 100%;
// 	padding: 6px 12px;
// 	font-size: 1rem;
// 	font-weight: 400;
// 	line-height: 1.5;
// 	color: #212529;
// 	background-clip: padding-box;
// 	appearance: none;
// 	border-radius: 0.375rem;
// 	transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
// }

// .phoneInput > input {
// 	border: 1px solid #ced4da !important;
// 	width: 100%;
// }

// .btnSubmit,
// .btnBack {
// 	color: white !important;
// 	font-weight: bold !important;
// 	font-size: 14px !important;
// 	margin-top: 8px;
// 	border-radius: 100px;
// 	margin: 8px 5px;
// }

// .btnSubmit {
// 	background: green !important;
// 	height: 40px;
// 	width: 40%;
// 	border-radius: '100px';
// }

// .btnBack {
// 	height: 40px;
// 	width: 40%;
// 	background: #cb2c2c !important;
// }

// .selectedCard {
// 	display: flex;
// 	margin-top: 12px;
// 	margin-bottom: 12px !important;
// 	border-radius: 10px;
// 	padding: 0 !important;
// 	background-color: #fff;
// 	cursor: pointer;
// 	overflow: hidden;
// 	width: 100% !important;

// 	.selectedCardDetails {
// 		margin: 0px 10px;
// 		width: 100%;
// 		display: flex;
// 		justify-content: center;
// 		align-items: center;
// 		flex-direction: column;
// 		color: #000;
// 	}
// }

// @media screen and (max-width: 300px) {
// 	.PhoneInput {
// 		width: 100% !important;
// 	}
// }

// // @media screen and (min-width: 301px) and (max-width: 600px) {
// // 	.pageHome {
// // 		background: url('../../../public/images/music-mobile.jpg') no-repeat;
// // 		background-size: cover;
// // 		overflow: hidden;
// // 		height: 100vh;
// // 	}
// // }

// // @media screen and (min-width: 601px) and (max-width: 1300px) {
// // 	.pageHome {
// // 		background: url('../../../public/images/music-pad.jpg') no-repeat;
// // 		background-size: cover;
// // 		overflow: hidden;
// // 		height: 100vh;
// // 	}
// // }
