.playing_container {
	display: flex;
	flex-direction: row;
	margin: 5px 20px;
	height: 75px;

	.playing_details {
		display: flex;
		flex-direction: row;
		width: 100%;
	}

	.track_details {
		display: block;
		text-align: left;
		margin-left: 8px;
	}

	.track_gif > img {
		width: 100px;
		height: auto;
	}

	.track_image {
		display: flex;
		text-align: left;
		width: 65px;
		height: 65px;
		background-repeat: no-repeat;
		background-size: contain;
		border-radius: 10px;
		overflow: hidden;
	}

	.track_gif {
		width: auto;
		height: auto;
		img {
			position: relative;
			bottom: 15px;
		}
	}

	.track_name {
		margin-bottom: 0;
		margin-left: 10px;
		color: #ffffff;
		font-size: 16px;
		font-weight: bold;
	}

	.track_name_description {
		margin-bottom: 0;
		margin-left: 10px;
		color: #ffffff;
		font-size: 16px;
		font-weight: 400;
	}

	.spotify_label {
		margin-bottom: 0;
		margin-left: 10px;
		color: #ffffff;
		font-size: 17px;
		font-weight: 300;
	}
}

@media screen and (max-width: 520px) {
	.track_gif {
		img {
			width: 70px !important;
		}
	}
}

// @media screen and (min-width: 521px) and (max-width: 800px) {
// 	.playing_container {
// 		.track_image {
// 			width: 40%;
// 		}
// 	}
// }
