.playlistContainer {
	padding: 0 50px;
	overflow-x: hidden;
}

.playlist {
	justify-content: center;
	margin: 20px 0;
	height: 500px;
}

.playlistItem {
	height: 200px;
	margin-top: 20px;
	margin-right: 60px;
	margin-bottom: 30px;
}

.searchContainer {
	margin: 20px 0 40px 0;
}

.btnSearch {
	padding: 7px;
	border-bottom-right-radius: 8px;
	border-top-right-radius: 8px;
	background: rgb(47, 45, 45);
	border: none;
	color: white;
	font-weight: bold;
	cursor: pointer;
	font-size: 14px;
	width: 60px;
}

.inputSearch {
	padding: 8px;
	border-bottom-left-radius: 8px;
	border-top-left-radius: 8px;
	border: 1px #c3c3c3 solid;
}

.boxShadow {
	box-shadow: 2px 5px 8px -5px rgba(224, 221, 221, 0.75);
	-webkit-box-shadow: 2px 5px 8px -5px rgba(224, 221, 221, 0.75);
	-moz-box-shadow: 2px 5px 8px -5px rgba(224, 221, 221, 0.75);
}

.card {
	width: 100%;
	height: 80%;
	border-radius: 10px;
	background-color: rgb(47, 45, 45);
	cursor: pointer;
	overflow: hidden;

	.card_details {
		margin: 0px 10px;
		width: 100%;
		display: flex;
		justify-content: center;
		color: #fff;
		align-items: center;
		flex-direction: column;
	}
}

.selectedCard {
	display: flex;
	margin-top: 20px;
	margin-bottom: 30px !important;
	border-radius: 10px;
	padding: 0 !important;
	background-color: rgb(47, 45, 45);
	cursor: pointer;
	overflow: hidden;
	width: 30% !important;

	.selectedCardDetails {
		margin: 0px 10px;
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		color: #fff;
	}
}

.noSelectable {
	background-color: rgb(102, 22, 22);
	cursor: not-allowed;
}

.btnSubmit {
	width: 100px;
	height: 35px;
	padding: 7px 20px;
	margin-bottom: 15px;
	border-radius: 100px;
	background: green;
	border: 1px solid #fff;
	color: white;
	font-weight: bold;
	cursor: pointer;
	font-size: 14px;
}

@media screen and (min-width: 1100px) {
	.searchContainer {
		margin: 20px 0 150px 0;
	}

	.selectedCard {
		width: 40% !important;
	}
}

@media screen and (min-width: 667px) {
	.selectedCard {
		width: 60% !important;
	}
}

@media screen and (max-width: 666px) {
	.playlistContainer {
		padding: 0 10px !important;
	}

	.selectedCard {
		width: 100% !important;
	}
}
