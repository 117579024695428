@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
		'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: 'Inter';
	word-break: break-word;
	margin-bottom: 0;
	color: #ffffff;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

input:focus {
	outline: none;
}

.PhoneInput {
	display: flex;
	align-items: center;
	width: 15%;
}
.PhoneInputInput {
	flex: 1 1 !important;
	min-width: 0 !important;
	width: 15% !important;
	padding: 8px !important;
	border: 1px solid #c3c3c3 !important;
	border-radius: 8px !important;
}

@media screen and (max-width: 500px) {
	.PhoneInput {
		width: 80%;
	}
	.PhoneInputInput {
		width: 80%;
	}
}

@keyframes latidos {
	from {
		transform: none;
	}
	50% {
		transform: scale(1.2);
	}
	60% {
		opacity: 0.5;
		transition: opacity 3s;
	}
	to {
		transform: none;
	}
}

@keyframes logoAnimation {
	0% {
		transform: scale(1);
	}
	50% {
		transform: scale(0.8);
	}
	100% {
		transform: scale(1);
	}
}

.heartBeat {
	animation-name: latidos;
	animation-duration: 1.2s;
	animation-iteration-count: infinite;
	transform-origin: center;
}

.heartBeatLogo {
	animation-name: logoAnimation;
	animation-duration: 1.2s;
	animation-iteration-count: infinite;
	transform-origin: center;
}

.react-tel-input .country-list .search {
	padding: 0 !important; 
}
