.inputContainer {
	width: 100%;
	display: flex;
	margin: 0 auto;
	flex-direction: column;

	.input {
		width: 100%;
		height: 73px;
		background-color: transparent;
		border: 1px solid #FFFFFF;
		border-radius: 5px;
		padding-left: 24px;
		padding-top: 20px;
		padding-right: 60px;
		padding-bottom: 20px;
		font-size: 24px;
		font-weight: 300;
		color: #fff;
	}

	.input::placeholder {
		font-family: 'Inter';
		font-size: 24px;
		color: #9e9b9b;
	}
	
	@media screen and (max-width: 900px) {
		.input::placeholder {
			font-family: 'Inter';
			font-size: 18px;
			color: #9e9b9b;
		}
	}
}
