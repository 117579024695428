.header {
	background-image: url('../../../public/images/header.jpg');
	background-size: 800px;
	background-repeat: no-repeat;
	height: 250px;
	width: 100%;
	border-radius: 0px 0px 20px 20px;
}

.container {
	display: flex;
	align-items: center;
	flex-direction: column;
	background-color: #ffffff;
	margin-bottom: 80px;

	.item {
		height: 160px;
		background-color: #ffff;
		border-radius: 8px;
		cursor: pointer;
		padding: 0px 0px 10px 0px;
		margin-bottom: 25px;
		font-size: 1.5em;
		box-shadow: -1px 2px 5px -1px rgba(0, 0, 0, 0.6);
		-webkit-box-shadow: -1px 2px 5px -1px rgba(0, 0, 0, 0.6);
		-moz-box-shadow: -1px 2px 5px -1px rgba(0, 0, 0, 0.6);

		.itemTitle {
			font-size: 1em;
			font-weight: bold;
			border: 1px solid #2e2e2e;
			padding-top: 0;
			margin-top: 0;
			background-color: #2e2e2e;
			color: #ffff;
			border-radius: 0 0 10px 10px;
		}

		.itemDescription {
			font-size: 0.5em;
			padding-left: 6px;
			padding-right: 6px;
		}
	}

	.coloredItem {
		color: #ffff;
		background-color: #08bdc0;
	}

	.pack {
		width: 100%;
		height: auto;
		border-radius: 8px;
		border: 2px solid #1b9a9cf2;
		cursor: pointer;
		margin-bottom: 30px;
		box-shadow: -1px 2px 5px -1px rgba(0, 0, 0, 0.6);
		-webkit-box-shadow: -1px 2px 5px -1px rgba(0, 0, 0, 0.6);
		-moz-box-shadow: -1px 2px 5px -1px rgba(0, 0, 0, 0.6);

		.packName {
			padding-top: 5px;
			font-size: 1.5em;
			font-weight: 700;
		}

		.packDescription {
			margin-top: 20px;
			margin-bottom: 50px;
			font-size: 1.2em;
			font-weight: 700;
		}

		.packPrice {
			font-size: 3em;
			font-weight: 800;
			color: #08bdc0;
		}
	}

	.coloredPack {
		background-color: #1b9a9cf2;
		color: #ffffff;
	}

	.coloredPrice {
		color: #ffffff !important;
		border: none !important;
	}

	.divisor {
		border-bottom: 1px solid #1b9a9cf2;
		width: 85%;
		margin: 0 auto;
	}

	.coloredDivisor {
		border-bottom: 1px solid #e8e6e6;
	}

	.inputSearch {
		padding: 8px;
		border-radius: 8px;
		border: 1px #c3c3c3 solid;
		margin-bottom: 10px;
	}

	.btnSearch {
		padding: 7px;
		border-radius: 8px;
		background: #709dd2;
		border: none;
		color: white;
		font-weight: bold;
		cursor: pointer;
		font-size: 14px;
	}

	.active {
		background: #709dd2;
	}
}

@media screen and (min-width: 801px) {
	.header {
		background: url('../../../public/images/header-pad.jpg') no-repeat;
		background-size: cover;
	}
}

@media screen and (min-width: 700px) {
	.item {
		width: 30% !important;
		height: 200px !important;

		.itemTitle {
			font-size: 1.2em !important;
		}

		.itemDescription {
			font-size: 0.8em !important;
		}
	}
}
