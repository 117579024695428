.authFormContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    width: 100vw;
    height: 100vh;
  }
  
.authForm {
    width: 420px;
    padding: 40px 15px;
    border-radius: 8px;
    background: rgba(44, 43, 43, 0.7);
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.18);
  }
  
  .authFormContent {
    padding-left: 12%;
    padding-right: 12%;
  }

  .btnSubmit {
    background: #6ad173 !important;
    color: white !important;
    font-weight: bold !important;
    font-size: 14px !important;
    margin-top: 20px;
  }