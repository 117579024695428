.headerContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.iconContainer {
    width: 10%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.titleContainer {
    width: 100%;
    text-align: center;
}

.headerIcon {
    margin-top: 8px;
}