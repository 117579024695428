body {
    background-color: #15212F;
}

.container {
    padding-left: 240px;
    padding-right: 240px;
    padding-top: 36px;
}

.title {
    font-size: 40px;
    font-weight: 700;
}

.fontFamily {
    font-family: 'Inter';
}


