.searchInputContainer {
	display: flex;
	justify-content: center;
	width: '100%';
	margin-top: 24px;

	.searchInput {
		font-size: 24px;
		color: #9e9b9b;
		width: 100%;
		max-width: 923px;
		min-width: 389px;
		height: 73px;
		background-color: transparent;
		border: 1px solid #d9d9d9;
		border-radius: 5px;
	}

	.searchInput::placeholder {
		font-size: 24px;
		color: #9e9b9b;
	}
}

.buttonsContainer {
	display: flex;
	flex-direction: row;
	justify-content: center;
	margin-top: 20px;
	position: fixed;
	height: 50px;
	bottom: 0px;
	left: 0px;
	right: 0px;
	margin-bottom: 20px;
}