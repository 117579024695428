.card {
	display: flex;
	margin: 20px 0;
	width: 100%;
	height: 80%;
	border-radius: 10px;
	background-color: rgb(47, 45, 45);
	cursor: pointer;
	overflow: hidden;

	.card_details {
		margin: 0px 10px;
		width: 100%;
		display: flex;
		justify-content: center;
		color: #fff;
		align-items: center;
		flex-direction: column;

		.name {
			font-size: 0.8em;
			margin: 0;
		}

		.description {
			font-size: 0.7em;
			font-weight: 650;
			margin: 0;
		}
	}
}

.no_selected_card {
	background-color: rgb(47, 45, 45);
}

.selected_card {
	background-color: green;
}

.no_selectable {
	background-color: rgb(102, 22, 22);
	cursor: not-allowed;
}

.box_shadow {
	box-shadow: 2px 5px 8px -5px rgba(224, 221, 221, 0.75);
	-webkit-box-shadow: 2px 5px 8px -5px rgba(224, 221, 221, 0.75);
	-moz-box-shadow: 2px 5px 8px -5px rgba(224, 221, 221, 0.75);
}
